// Generated by Framer (fb31e5e)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { useRef } from "react";

const enabledGestures = {x5oL9E3UT: {hover: true}};

const serializationHash = "framer-npWEh"

const variantClassNames = {x5oL9E3UT: "framer-v-cqoocs"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({height, id, usernameOnX, width, ...props}) => { return {...props, gGRKGaKcy: usernameOnX ?? props.gGRKGaKcy ?? "@Astrone"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;usernameOnX?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const fallbackRef = useRef<HTMLElement>(null)

const refBinding = ref ?? fallbackRef

const defaultLayoutId = React.useId()

const { activeLocale, setLocale } = useLocaleInfo()

const componentViewport = useComponentViewport()

const {style, className, layoutId, variant, gGRKGaKcy, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "x5oL9E3UT", enabledGestures, ref: refBinding, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const sharedStyleClassNames = []

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(scopingClassNames, "framer-cqoocs", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"x5oL9E3UT"} ref={refBinding} style={{...style}} {...addPropertyOverrides({"x5oL9E3UT-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--framer-font-size": "14px", "--framer-letter-spacing": "0.14px", "--framer-line-height": "22px", "--framer-text-color": "var(--extracted-r6o4lv, var(--token-759ab450-1ac8-4261-aeaf-31bef1a5bafb, rgb(128, 133, 143)))"}}>@Astrone</motion.p></React.Fragment>} className={"framer-1pkzvz"} fonts={["Inter"]} layoutDependency={layoutDependency} layoutId={"iQeeM9mg7"} style={{"--extracted-r6o4lv": "var(--token-759ab450-1ac8-4261-aeaf-31bef1a5bafb, rgb(128, 133, 143))", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline"}} text={gGRKGaKcy} variants={{"x5oL9E3UT-hover": {"--extracted-r6o4lv": "var(--token-65f1c14e-1674-4824-8eb9-d6b8315179f0, rgb(230, 62, 2))"}}} verticalAlignment={"top"} withExternalLayout {...addPropertyOverrides({"x5oL9E3UT-hover": {children: <React.Fragment><motion.p style={{"--framer-font-size": "14px", "--framer-letter-spacing": "0.14px", "--framer-line-height": "22px", "--framer-text-color": "var(--extracted-r6o4lv, var(--token-65f1c14e-1674-4824-8eb9-d6b8315179f0, rgb(230, 62, 2)))"}}>@Astrone</motion.p></React.Fragment>}}, baseVariant, gestureVariant)}/></motion.div></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-npWEh.framer-19syz5w, .framer-npWEh .framer-19syz5w { display: block; }", ".framer-npWEh.framer-cqoocs { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: visible; padding: 0px; position: relative; width: min-content; }", ".framer-npWEh .framer-1pkzvz { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-npWEh.framer-cqoocs { gap: 0px; } .framer-npWEh.framer-cqoocs > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-npWEh.framer-cqoocs > :first-child { margin-left: 0px; } .framer-npWEh.framer-cqoocs > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 22
 * @framerIntrinsicWidth 66
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"HH0KDUatj":{"layout":["auto","auto"]}}}
 * @framerVariables {"gGRKGaKcy":"usernameOnX"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerjM4KV14B7: React.ComponentType<Props> = withCSS(Component, css, "framer-npWEh") as typeof Component;
export default FramerjM4KV14B7;

FramerjM4KV14B7.displayName = "Creator Name '@'";

FramerjM4KV14B7.defaultProps = {height: 22, width: 66};

addPropertyControls(FramerjM4KV14B7, {gGRKGaKcy: {defaultValue: "@Astrone", displayTextArea: false, title: "@ username on X", type: ControlType.String}} as any)

addFonts(FramerjM4KV14B7, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://framerusercontent.com/assets/5vvr9Vy74if2I6bQbJvbw7SY1pQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://framerusercontent.com/assets/EOr0mi4hNtlgWNn9if640EZzXCo.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://framerusercontent.com/assets/Y9k9QrlZAqio88Klkmbd8VoMQc.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://framerusercontent.com/assets/OYrD2tBIBPvoJXiIHnLoOXnY9M.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://framerusercontent.com/assets/JeYwfuaPfZHQhEG8U5gtPDZ7WQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://framerusercontent.com/assets/vQyevYAyHtARFwPqUzQGpnDs.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://framerusercontent.com/assets/b6Y37FthZeALduNqHicBT6FutY.woff2", weight: "400"}]}], {supportsExplicitInterCodegen: true})